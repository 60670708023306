export  const config={
    // Development
    // API_URL : 'https://api.restro360.com',
    // // API_URL : 'http://192.168.1.11:8080',
    // IMG_UPLOAD_URL : 'https://api.restro360.com',
    // GA_MeasurementID: 'G-1V4BWYRHXR', // restro
    // CLIENT: "Restro",
    // // Pusher_Key: '4810430304445c739ba3',
    // Pusher_Key: '',
    // STATUS:"",

    // Production Potful
    CLIENT: "Potful",
    API_URL : 'https://api.restro.app',
    IMG_UPLOAD_URL : 'https://admin.restro.app',
    GA_MeasurementID: 'G-1V4BWYRHXR', // potful,
    // Pusher_Key: 'e9085511697be3d83773',
    Pusher_Key: '',
    STATUS:"d-none",
    ClientID:'7495c6b5-2da6-48dd-8635-4aefcc8a0830',
    ClientSecret: '08132e83-535a-4c58-bb52-9ad058104678'

        // test Potful
        // CLIENT: "Potful",
        // API_URL : 'https://api-beta.restro.app',
        // IMG_UPLOAD_URL : 'https://admin.restro.app',
        // GA_MeasurementID: 'G-1V4BWYRHXR', // potful,
        // // Pusher_Key: 'e9085511697be3d83773',
        // Pusher_Key: '',
        // STATUS:"d-none",
        // ClientID:'7495c6b5-2da6-48dd-8635-4aefcc8a0830',
        // ClientSecret: '08132e83-535a-4c58-bb52-9ad058104678'

    // Production Retro in Metro
    // CLIENT: "Restro",
    // API_URL : 'https://api-001.restro.app',
    // IMG_UPLOAD_URL : 'https://admin-001.restro.app',
    //     GA_MeasurementID: 'G-QNBDC681JE', // Retro in Metro,
    // Pusher_Key: '94805154c54a5c606d19',
    // STATUS:"d-none",
}
// AIzaSyDbGyWeFsL0XanjbAA8TghXMFuAiBcuQmA
